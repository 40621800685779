import { proxy, useSnapshot } from "valtio";
import { GeofenceSelectionType } from "../enum";
import { useCircleGeofenceState } from "./circle-geofence-state";
import { usePolylineRouteState } from "./polyline-route-state";

export class MapState {
  geofenceSelection: GeofenceSelectionType = GeofenceSelectionType.Routes;
  loading: boolean = false;

  setIsLoading(loading: boolean) {
    this.loading = loading;
  }

  setGeofenceSelection(selection: GeofenceSelectionType) {
    this.geofenceSelection = selection;
    useCircleGeofenceState().setOnGeofenceMode(null);
    useCircleGeofenceState().setSelectedGeofence(null);
    usePolylineRouteState().setSelectedRoute(null);
  }
}

export const mapState = proxy(new MapState());

export const useMapSnap = () => useSnapshot(mapState);
export const useMapState = () => mapState;
