import { geofenceToLatLng } from './mapUtils'
import {
  Circle,
  CircleMarker,
  Polygon,
  MapContainer,
  TileLayer,
  useMap,
  useMapEvents,
  Polyline,
} from "react-leaflet";
import "leaflet-arrowheads";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet/dist/leaflet.css";
import { useCallback, useEffect, useState } from "react";
import { RouteMonitorData } from "../../types";
import {
  Text,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  VStack,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import L from "leaflet";
import { GeofenceSelectionType } from "../../enum";
import { TravelTimeToolbar } from "./GeofenceToolbar";
import { Locations } from "./Locations/Locations";
import {
  polylineTemplate,
  polylineTemporaryID,
} from "./mapUtils";
import { Routes } from "./Routes";
import { SearchBox } from "./SearchBox/SearchBox";
import { geofenceFilter, routeFilter } from "./SearchBox/searchUtils";
import {
  useCircleGeofenceSnap,
  useCircleGeofenceState,
  GeofenceMode,
  circleGeofenceState,
} from "../../state/circle-geofence-state";
import {
  usePolylineRouteSnap,
  usePolylineRouteState,
  polylineRouteState,
} from "../../state/polyline-route-state";
import { useMapSnap } from "../../state/map_state";
import { AllPolylines } from "./polylinesAndCircles/routes/components/AllPolylines";
import { NewPolyline } from "./polylinesAndCircles/routes/components/NewPolyline";
import { useCircleStyles } from "./polylinesAndCircles/geofences/components/utils/useCircleStyles";
import { Geofence, GeofenceData } from "../../http/http";
import { useAddGeofence } from './polylinesAndCircles/geofences/functions/useAddGeofence';

type Props = {
  onAddRouteMonitor: (direction: RouteMonitorData) => void;
};

export const TravelTimesMap = ({ onAddRouteMonitor }: Props) => {
  const selectedGeofence = useCircleGeofenceSnap().selectedGeofence;
  const geofenceMode = useCircleGeofenceSnap().geofenceMode;
  const setOnNewGeofenceMode = useCircleGeofenceState();

  const routeMonitors = usePolylineRouteSnap().routeMonitors;
  const selectedRoute = usePolylineRouteSnap().selectedRoute;
  const routeState = usePolylineRouteState();

  const isLoading = useMapSnap().loading;
  const geofenceSelection = useMapSnap().geofenceSelection;
  const [addBothDirections, setAddBothDirections] = useState(false);

  const [layerGroupForOnePolyline] = useState<any>(L.layerGroup());
  const [layerGroupForAllPolylines] = useState(L.layerGroup());

  const [filteredRoutes, setFilteredRoutes] = useState(
    routeState.routeMonitors
  );

  const [filteredGeofences, setFilteredGeofences] = useState(
    circleGeofenceState.geofences
  );

  const [geofenceElements, setGeofenceElements] = useState<JSX.Element[]>([]);

  const { isOpen, onClose, onOpen } = useDisclosure({ defaultIsOpen: true });
  const { circleStyle, circleMarkerStyle } = useCircleStyles();

  const { addGeofence } = useAddGeofence();
  let [userClickCoordinates, setUserClickCoordinates] = useState<L.LatLng[]>([]);

  useEffect(() => {
    if (!isLoading) {
      onClose();
    } else {
      onOpen();
    }
  }, [isLoading]);

  useEffect(() => {
    setFilteredGeofences(circleGeofenceState.geofences);
    setGeofenceElements(
      circleGeofenceState.geofences.map((gf) => geofenceToMapObject(gf))
    );
  }, [circleGeofenceState.geofences]);

  useEffect(() => {
    setFilteredRoutes(polylineRouteState.routeMonitors);
  }, [polylineRouteState.routeMonitors]);

  const directionEventHandlers = (obj: GeofenceData) => ({
    click() {
      if (
        selectedRoute === null &&
        geofenceSelection === GeofenceSelectionType.Routes
      ) {
        polylineRouteState.setSelectedRoute(polylineTemplate(obj, obj));
      } else if (
        selectedRoute !== null &&
        selectedRoute.from.id !== obj.id &&
        geofenceSelection === GeofenceSelectionType.Routes
      ) {
        let tempObj: RouteMonitorData = {
          title: selectedRoute.from.name + " - " + obj.name,
          id: selectedRoute.id,
          from: selectedRoute.from as GeofenceData,
          to: obj,
          via: selectedRoute.via?.map((id) => id),
        };
        onAddRouteMonitor(tempObj);
        layerGroupForOnePolyline.clearLayers();
        polylineRouteState.setSelectedRoute(null);
      }
    },
  });

  function drawRoutes(leafletMap: L.Map) {
    if (selectedRoute) {
      const color = "red"
      let l = AllPolylines(color, selectedRoute as RouteMonitorData)
      layerGroupForAllPolylines
        .addLayer(l)
        .addTo(leafletMap)

      leafletMap.flyToBounds(l.getBounds());
    } else {
      const color = "black"
      for (let rm of routeMonitors) {
        let l = AllPolylines(color, rm as RouteMonitorData)
        layerGroupForAllPolylines
          .addLayer(l)
          .addTo(leafletMap)
      }
    }
  }

  function MapEventHandler() {
    let leafletMap = useMap();
    layerGroupForAllPolylines.clearLayers();

    if (geofenceSelection === GeofenceSelectionType.Routes) {
      drawRoutes(leafletMap)
    }else if (selectedGeofence){
      let ll = geofenceToLatLng(selectedGeofence as GeofenceData)
      leafletMap.flyTo(ll);
    }

    useMapEvents({
      contextmenu: (e: any) => {
        console.log("Map was right clicked")
        if (geofenceMode === GeofenceMode.Scaling) {
          if (userClickCoordinates.length > 3) {
            // setUserClickCoordinates(userClickCoordinates.slice(0, userClickCoordinates.length - 1))
            const name = prompt("Name of the geofence")
            if (name) {
              let gf = {
                name: name,
                geometry: {
                  polygon: {
                    coordinates: userClickCoordinates.map((c) => {
                      return { la: c.lat, lo: c.lng }
                    })
                  }
                }
              }
              addGeofence(gf as Geofence)
            }
          }
          setUserClickCoordinates([]);
          setOnNewGeofenceMode.setOnGeofenceMode(null);
        }
      },
      click: (e: any) => {
        if (
          geofenceMode === GeofenceMode.Scaling &&
          geofenceSelection === GeofenceSelectionType.Locations
        ) {
          let appendCoords = userClickCoordinates.length == 0 ? [e.latlng, e.latlng] : [e.latlng];
          setUserClickCoordinates(userClickCoordinates.concat(appendCoords))
        }
      },
      mousemove(e: any) {
        if (
          geofenceSelection === GeofenceSelectionType.Locations &&
          geofenceMode === GeofenceMode.Scaling
        ) {
          setUserClickCoordinates(userClickCoordinates.slice(0, userClickCoordinates.length - 1).concat([e.latlng]))
        } else if (
          selectedRoute?.id === polylineTemporaryID &&
          geofenceSelection === GeofenceSelectionType.Routes
        ) {
          const via: string[] = []
          via.push(...selectedRoute.via)
          let tempObj = {
            title: selectedRoute.title,
            id: selectedRoute.id,
            from: selectedRoute.from,
            to: {
              ...selectedRoute.to,
              latlng: e.latlng,
            },
            via: via,
          };
          polylineRouteState.setSelectedRoute(tempObj as RouteMonitorData);

          if (selectedRoute?.id === polylineTemporaryID) {
            let newPolyline = NewPolyline(geofenceToLatLng(selectedRoute.from as GeofenceData), {
              lat: e.latlng.lat - 0.1,
              lng: e.latlng.lng - 0.1,
            });

            layerGroupForOnePolyline.clearLayers();
            layerGroupForOnePolyline.addLayer(newPolyline).addTo(leafletMap);
          }
        }
      },
    });
    return null;
  }

  const polygonGeofenceInMaking = useCallback(() => {
    if (userClickCoordinates.length == 2) {
      return (
        <Polyline
          positions={userClickCoordinates}
          pathOptions={{ color: "red" }}
        >

        </Polyline>
      )
    }
    if (userClickCoordinates.length < 3) {
      null
    }
    return (
      <Polygon
        positions={userClickCoordinates}
        pathOptions={{ color: "red" }}
      >

      </Polygon>

    );
  }, [userClickCoordinates]);

  const geofenceToMapObject = (obj: GeofenceData, color: "red" | "black" = "black") => {
    let polygon = obj.geometry.polygon;
    if (polygon) {
      let coordinates = polygon.coordinates.map((c) => [c.la, c.lo] as L.LatLngTuple);
      if (coordinates.length < 3) {
        throw new Error("Polygon coordinates are not valid");
      }
      let centerOfPoly = geofenceToLatLng(obj);
      return (
        <Polygon
          eventHandlers={directionEventHandlers(obj)}
          key={obj.id}
          positions={coordinates}
          pathOptions={circleStyle(obj)}
        >
          <CircleMarker
            center={centerOfPoly}
            radius={3}
            pathOptions={circleMarkerStyle(obj)}
          ></CircleMarker>

        </Polygon>)
    }

    let circle = obj.geometry.circle;
    if (!circle) {
      throw new Error("Geofence data is neither polygon nor circle");
    }
    return (
      <Circle
        eventHandlers={directionEventHandlers(obj)}
        key={obj.id}
        center={[circle.la, circle.lo]}
        pathOptions={circleStyle(obj)}
        radius={circle.radius}
      >
        <CircleMarker
          center={[circle.la, circle.lo]}
          radius={3}
          pathOptions={circleMarkerStyle(obj)}
        ></CircleMarker>
      </Circle>
    );
  };

  const createGeofenceWithColorFromId = (
    gfId: string,
    color: "red" | "black"
  ) => {
    const gf = filteredGeofences.find((g) => g.id === gfId);
    if (!gf) return null;
    return geofenceToMapObject(gf, color);
  };

  const getGeofenceName = (id: string) => {
    const gf = filteredGeofences.find((g) => g.id === id);
    if (!gf) return "";
    return gf.name;
  };

  const loadingModal = () => {
    return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <HStack>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor={"brand.lineGrey"}
                color={"brand.darkGreen"}
                size="xl"
              />
              <Text fontSize={"2xl"} color={"brand.green"}>
                Loading data...
              </Text>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };

  let mapVisibleGeofences: GeofenceData[] = filteredGeofences;
  if (geofenceSelection === GeofenceSelectionType.Locations) {
    if (selectedGeofence) {
      mapVisibleGeofences = [selectedGeofence as GeofenceData]
    }
  } else if (selectedRoute) {
    let route = polylineRouteState.routeMonitors.filter((r) => r.id === selectedRoute.id)[0];
    mapVisibleGeofences = [route.from as GeofenceData, route.to as GeofenceData]
    if (route.via) {
      route.via.forEach((gfId) => {
        const gf = filteredGeofences.find((g) => g.id === gfId);
        if (gf) {
          mapVisibleGeofences.push(gf)
        }
      })
    }
  }

  console.log("Rendering TravelTimesMap", userClickCoordinates.length)

  return (
    <VStack w="full" margin={10}>
      <div style={{ width: "90%" }}>
        <MapContainer
          style={{ width: "100%", height: "55em" }}
          center={[59.8, 12.427194]}
          zoom={6}
          scrollWheelZoom={true}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />

          {polygonGeofenceInMaking()}
          {mapVisibleGeofences && mapVisibleGeofences.map((v) => geofenceToMapObject(v as GeofenceData))}
          <MapEventHandler />
        </MapContainer>
      </div>

      <TravelTimeToolbar
        onAddBothDirections={() => setAddBothDirections(!addBothDirections)}
      />
      {geofenceSelection === GeofenceSelectionType.Locations ? (
        <>
          {geofenceMode === GeofenceMode.Scaling ? (
            <>
              <Text>Click on map to add coordinates. Right click to finish geofence</Text>
            </>
          ) : (
            <>
              <HStack>
                <SearchBox
                  setFilteredList={setFilteredGeofences}
                  filter={geofenceFilter}
                  itemsArray={circleGeofenceState.geofences}
                />
                <Button
                  onClick={() => setOnNewGeofenceMode.setOnGeofenceMode(GeofenceMode.Scaling)}
                  size={"sm"}
                >
                  Create new geofence
                </Button>
              </HStack>
              <Locations geofences={filteredGeofences} />
            </>
          )
          }
        </>
      ) : (
        <>
          <HStack>
            <SearchBox
              setFilteredList={setFilteredRoutes}
              filter={routeFilter}
              itemsArray={polylineRouteState.routeMonitors}
            />
            <Routes
              geofenceElements={geofenceElements}
              setGeofenceElements={setGeofenceElements}
              createGeofenceCirclesWithColor={createGeofenceWithColorFromId}
              routeMonitors={filteredRoutes}
              getGeofenceName={getGeofenceName}
            />
          </HStack>
        </>
      )}
      {loadingModal()}
    </VStack>
  );
};
