import { Alert } from "../../Alert";
import { PencilIcon, TrashIcon } from "../../../assets/icons/icon";
import { HStack, IconButton } from "@chakra-ui/react";
import { useCallback } from "react";
import {
  useCircleGeofenceSnap,
  useCircleGeofenceState,
} from "../../../state/circle-geofence-state";
import { circleTemporaryID } from "../mapUtils";
import { useDeleteGeofence } from "../polylinesAndCircles/geofences/functions/useDeleteGeofence";
import { GeofenceData } from "../../../http/http";

export type Props = {
  setShowAlert: (showAlert: GeofenceData | null) => void;
  geofence: GeofenceData;
  showAlert: GeofenceData | null;
  onEditGeofence?: () => void;
};

export const DeleteAndEditLocation = ({
  setShowAlert,
  geofence,
  showAlert,
  onEditGeofence,
}: Props) => {
  let selectedArea = useCircleGeofenceSnap().selectedGeofence;
  
  const { deleteGeofence: deleteG } = useDeleteGeofence();
  const selectedAlert = useCallback(
    (selected: GeofenceData | null) => {
      return selected != null && selected === showAlert ? true : false;
    },
    [showAlert]
  );

  return (
    <HStack>
      {geofence.id !== circleTemporaryID && (
        <IconButton
          aria-label="Edit"
          size={"xs"}
          onClick={onEditGeofence}
          backgroundColor="transparent"
          boxShadow="none"
          icon={<PencilIcon />}
        />
      )}

      <Alert
        onCancel={() => [
          useCircleGeofenceState().setSelectedGeofence(null),
          useCircleGeofenceState().setOnGeofenceMode(null),
          setShowAlert(null),
        ]}
        BodyText={
          "Removing a geofence will also delete any route and data connected to it. Are you sure you want to delete this geofence?"
        }
        ApplyButtonText={"Delete"}
        HeadingText={"Delete geofence"}
        onApply={() => { deleteG(selectedArea as GeofenceData) }}
        isOpen={selectedAlert(geofence)}
        TriggerButton={
          <IconButton
            size={"xs"}
            onClick={() => [
              geofence.id !== circleTemporaryID
                ? [
                  useCircleGeofenceState().setSelectedGeofence(geofence),
                  setShowAlert(geofence),
                ]
                : deleteG(geofence),
            ]}
            backgroundColor="transparent"
            icon={<TrashIcon />}
            aria-label="Open menu"
            boxShadow="none"
          />
        }
      />
    </HStack>
  );
};
